<template>
  <section class="main-sec">
    <section class="start-sec mt-3">
      <main class="main-body-header">資源地圖問卷</main>

      <div v-for="(item, idx) in questions" :key="idx">
        <main class="main-body sec-1">
          <header class="main-body-question">
            {{ item.question }}
          </header>
        </main>
        <main class="main-body sec-100">
          <b-form-radio-group
            stacked
            v-model="item.answer1"
            :options="item.option1"
            :class="`grid-col-${item.option1.length}`"
            @change="updateRadioItems(item, idx)"
          ></b-form-radio-group>
        </main>

        <main class="main-body">
          <b-form-checkbox-group
            v-model="item.anwser2"
            :options="item.option2"
            @change="updateItems(item)"
            :disabled="item.answer1 !== item.option1[0]"
          ></b-form-checkbox-group>
        </main>
      </div>

      <b-button
        size="sm"
        variant="primary"
        class="mr-2"
        style="display:inline-block;width:80px;height:40px;margin-top:20px;margin-left:750px"
        @click="submitAnswer"
      >
        <i class="fas fa-save mr-1"></i>儲存
      </b-button>
      <div style="height:80px;"></div>
    </section>
  </section>
</template>

<script>
import queryString from "query-string";
import { store } from "@/store/global.js";
import moment from "moment";

const wants = ["Y", "N"];

const q2Opts = ["看電影", "打球", "打遊戲", "桌遊", "羽球"];

const q3Opts = ["修電腦", "內科", "外科"];

const q4Opts = [
  "學涯(例如：提供課業上的協助)",
  "職涯(例如：提供未來職業規劃的協助)",
  "生涯(例如：提供生活以及情感上的協助)",
];

export default {
  data() {
    return {
      questions: [],
    };
  },

  computed: {},
  methods: {
    updateRadioItems(item, idx) {
      requestAnimationFrame(() => {
        if (item.answer1 == item.option1[1]) {
          this.questions[idx].anwser2 = [];
        }
      });
    },
    updateItems(item) {
      requestAnimationFrame(() => {
        if (item.anwser2.length < 4) return;
        item.anwser2.shift();
      });
    },
    getFinalAnswer(answer, options) {
      let final = "";
      if (
        typeof answer == "string" ||
        typeof answer == "number" ||
        answer == null
      ) {
        final = options.map((s, i) => (answer == s ? "Y" : "N")).join(",");
      } else {
        final = options
          .map((s, i) => (answer.find((s2) => s2 == s) ? "Y" : "N"))
          .join(",");
      }
      return final;
    },
    async submitAnswer() {
      try {
        // await window.axios.post("LocalRecord/SetAnswer", obj);
        // this.$root.$emit("get-newestsurvey-data");
        // this.$bvToast.toast(`儲存成功`, {
        //   title: "個人資料",
        //   autoHideDelay: 5000,
        //   variant: "success",
        // });
      } catch (e) {
        // alert(e);
      }
    },
    getOriginalAnswer(final, options) {
      let originalAnswer = [];
      originalAnswer =
        (final &&
          final
            .split(",")
            .map((s, i) => (s == "Y" ? options[i] : ""))
            .filter((s) => s)) ||
        [];

      return originalAnswer;
    },

    async getQuestion() {
      try {
        const items = await window.axios.get("ResourceMap/GetQuestion");
        this.questions = [...items];
        this.questions = this.questions.map((s) => ({
          question: s.Question,
          option1: s.Answer.map((k, index) => k.Ans),
          answer1: "",
          option2: s.Answer[0].AnsCheck ? s.Answer[0].AnsCheck : [],
          answer2: [],
        }));
      } catch (e) {
        alert(e);
      }
    },
  },
  watch: {},
  mounted() {
    this.getQuestion();
  },
};
</script>

<style lang="scss" scoped>
.main-sec {
  margin: 0 auto;
}

.main-body .flast {
  display: inline-block;
  margin-right: 10px;
}
.main-body-header {
  background: #646b74;
  height: 42px;
  color: #fff;
  font-size: 20px;
  padding-left: 10px;
  line-height: 42px;
  text-align: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.main-body {
  border: 1px solid #bababa;
  border-top: none;
  padding: 10px;
  &.sec-1 {
    border-bottom: none;
  }
  &.sec-100 {
    border-bottom: none;
    padding-bottom: 0;
  }
}
.start-sec {
  width: 900px;
  margin: 0 auto;
}

.sec-1 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.sec-2,
.sec-100 {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 1rem;
}
.sec-3 {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  gap: 1rem;
}
.sec-6 {
  display: grid;
  grid-template-columns: repeat(6, max-content);
  gap: 1rem;
}

@for $i from 1 through 15 {
  .grid-col-#{$i} {
    display: grid;
    grid-template-columns: repeat($i, 1fr);
    gap: 1rem;
  }
}
</style>
